<template>
    <div id="clinic-detail">
        <div class="container" v-if="detail">
            <dl class="breadcrumb">
                <dt>您當前位置:</dt>
                <dd><a href="/doctor/list">預約掛號</a><svg t="1599534847217" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2986" width="16" height="16"><path d="M595.712 526.112l-205.44-197.28c-8.864-10.432-7.504-25.968 3.024-34.688 10.528-8.736 26.24-7.36 35.088 3.072l205.424 197.28c8.864 10.432 7.504 25.968-3.008 34.688S604.56 536.56 595.712 526.112z" p-id="2987" fill="#646464"></path><path d="M390.256 695.68l205.44-197.28c8.864-10.432 24.56-11.808 35.088-3.088 10.528 8.736 11.872 24.272 3.024 34.688l-205.44 197.28c-8.848 10.432-24.56 11.808-35.088 3.072C382.752 721.664 381.392 706.128 390.256 695.68z" p-id="2988" fill="#646464"></path></svg></dd>
                <dd><a href="/hospital/list">全部診所</a><svg t="1599534847217" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2986" width="16" height="16"><path d="M595.712 526.112l-205.44-197.28c-8.864-10.432-7.504-25.968 3.024-34.688 10.528-8.736 26.24-7.36 35.088 3.072l205.424 197.28c8.864 10.432 7.504 25.968-3.008 34.688S604.56 536.56 595.712 526.112z" p-id="2987" fill="#646464"></path><path d="M390.256 695.68l205.44-197.28c8.864-10.432 24.56-11.808 35.088-3.088 10.528 8.736 11.872 24.272 3.024 34.688l-205.44 197.28c-8.848 10.432-24.56 11.808-35.088 3.072C382.752 721.664 381.392 706.128 390.256 695.68z" p-id="2988" fill="#646464"></path></svg></dd>
                <dd><span>診所簡介</span></dd>
            </dl>
            <div class="content">
                <div class="clinic-info">
                    <img :src="detail.logo" class="icon-clinic">
                    <div class="col">
                        <h1 class="name" v-text="detail.clinicname || '' "></h1>
                        <p class="icon-p tel" :title="`電話：${detail.contacttel}${detail.contacttelother ? `（${detail.contacttelother}）`: ''}`" v-text="`${detail.contacttel || ''}${detail.contacttelother ? `（${detail.contacttelother}）`: ''}`"></p>
                        <p v-if="detail.workdays && '1,2,3,4,5,6,7'.indexOf(detail.workdays) == -1"
                            class="icon-p work-time"
                            v-text="`${detail.workdays.split(',').map(d=>weeks[d-1]).join(',')} ${detail.workstarttime.substring(0, 5)}-${detail.workendtime.substring(0, 5)}${detail.worktimeremark && `(${detail.worktimeremark})`}`"
                            :title="`工作時間: ${detail.workdays.split(',').map(d=>weeks[d-1]).join(',')}} ${detail.workstarttime.substring(0, 5)}-${detail.workendtime.substring(0, 5)}${detail.worktimeremark && `(${detail.worktimeremark})`}`"
                        ></p>
                        <p v-if="detail.workdays && '1,2,3,4,5,6,7'.indexOf(detail.workdays) != -1"
                            class="icon-p work-time"
                            v-text="`${detail.workdays.split(',').map(d=>weeks[d-1]).shift()}至${detail.workdays.split(',').map(d=>weeks[d-1]).pop()} ${detail.workstarttime.substring(0, 5)}-${detail.workendtime.substring(0, 5)}${detail.worktimeremark && `(${detail.worktimeremark})`}`"
                            :title="`工作時間: ${detail.workdays.split(',').map(d=>weeks[d-1]).shift()}至${detail.workdays.split(',').map(d=>weeks[d-1]).pop()} ${detail.workstarttime.substring(0, 5)}-${detail.workendtime.substring(0, 5)}${detail.worktimeremark && `(${detail.worktimeremark})`}`"
                        ></p>
                        <div class="row">
                            <p class="icon-p address" :title="`地址：${detail.address}`" v-text="detail.address"></p>
                            <span class="gps" @click="openMap(detail)">地圖</span>
                        </div>
                        <p class="intro" v-text="detail.introduce"></p>
                    </div>
                </div>
                <div class="clinic-doctors">
                    <div class="hint" v-if="doctorList">醫院醫師</div>
                    <div class="doctor-list" v-if="doctorList">
                        <div class="doctor-item" v-for="doctor in doctorList" :key="doctor.globaluserid">
                            <i :class="{'doctor-item-avatar': true, 'man': doctor.sex == 1}"></i>
                            <div class="doctor-item-name"><router-link :to="'/doctor/' + doctor.globaluserid" v-text="doctor.username"></router-link></div>
                            <div :class="{'booking-count': true, 'show': doctor.isdisplayorder == true}" v-text="`已約${doctor.totalordercount}次`"></div>
                            <div class="doctor-item-address-list">
                                <router-link class="doctor-item-address" :to="`/doctor/list?AreaLv1=${doctor.arealevel1id}&AreaLv2=${doctor.arealevel2id}`" v-text="`${detail.arealevel1name} ${detail.arealevel2name}`"></router-link>
                            </div>
                            <div class="doctor-item-book" @click="onBooingBtnClick(doctor)">預約</div>
                        </div>
                    </div>
                    <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
                    <div class="lds-ellipsis" v-if="loadingDoctor"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
            <v-booking-time :callback="onBookingCallback" v-if="isBooking" :doctor="this.bookingDoctor"/>
            <a-modal 
                width="1000px"
                style="top: 20px;"
                v-model="show"
                title="地圖" 
                :footer="null"
            >
                <div style="width: 1000px; height: 590px; display: flex; flex-direction: column;">
                    <iframe width="100%" height="100%" frameborder="0" :src="`https://www.google.com/maps?q=${(detail.address || '').replace(/樓\w+室$/, '樓')}&loc:${detail.lat}+${detail.lng}&hl=zh-hk&z=16&t=p&output=embed`" @click="show=false"></iframe>
                </div>
            </a-modal>
        </div>
        <loading v-if="loading" />

    </div> 
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import vBookingTime from '../../../layouts/BookingTime'
    import { gmapApi } from 'vue2-google-maps'
	export default {
        computed: {
            google: gmapApi
        },
        components: {
            Loading,
            vBookingTime,
            SplitPage,
        },
        data(){
            return {
                detail: null,
                loading: true,
                weeks: [
                    '星期一',
                    '星期二',
                    '星期三',
                    '星期四',
                    '星期五',
                    '星期六',
                    '星期日',
                ],
                isBooking: false,
                show: false,
                center: {lat: 22.502703, lng: 114.127960},
                markers: {
                    0: {
                        name: '明月堂-上水廣場店',
                        address: '地址：新界上水龍琛路39號上水廣場9樓901B室',
                        lat: '22.502703',
                        lng: '114.127960'
                    },
                    1: {
                        name: '明月堂-太子聯合廣場店',
                        address: '地址：九龍旺角彌敦道760號東海大廈12樓9室',
                        lat: '22.363482',
                        lng: '114.133302'
                    },
                    2: {
                        name: '明月堂-紅磡黃埔店',
                        address: '地址：九龍紅磡九龍紅磡九龍紅磡蕪湖街金輝行地下4號鋪',
                        lat: '22.306615',
                        lng: '114.186828'
                    }
                },
                infoPosition: null,
                infoTitle: null,
                infoDesc: null,
                infoOpened: false,
                infoCurrentKey: null,
                infoOptions: {
                    // pixelOffset: {
                    //     width: 0,
                    //     height: -135
                    // },
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: false
                },

                bookingDoctor: null,
                loadingDoctor: true,
                showDoctor: false,
                visible: false,
                pageNo: 1,
                pageSize: 5,
                total: 0,
                doctorList: null,
            }
        },
        mounted: function(){
            var clinicId = this.$route.params.clinicId
            if(clinicId == parseInt(clinicId)) {
                this.detail = JSON.parse(sessionStorage.getItem('detail'))
                this.loading = false
                this.getDoctors()
            } else {
                this.$router.push('/hospital/list')
            }
        },
        methods: {
            getDetail: function(){
                this.$store.dispatch('getClinicDetail', {clinicid: this.$route.params.clinicId})
                .then(detail => {
                    this.loading = false
                    this.detail = detail

                    this.getDoctors()
                })
                .catch(error => {
                    this.loading = false
                    this._toast.warning(this, error)
                })
            },
            getDoctors: function(){
                this.$store.dispatch('getDoctorsByClinic', {clinicid: this.detail.clinicid, pageNo: this.pageNo, pageSize: this.pageSize})
                .then((data)=>{
                    this.doctorList = data.list || []
                    this.total = data.total || 0
                    this.visible = this.total > 0
                    this.loadingDoctor = false
                })
            },
            onPageChanged: function(page) {
                if(page == this.pageNo) return
                this.pageNo = page
                this.loadingDoctor = true
                this.getDoctors()
            },
            onBooingBtnClick: function(doctor){
                if(!this.$store.getters.clientId) {
                    this._toast.warning(this, '未登录')
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 1500)
                    return
                }
                this.bookingDoctor = doctor
                this.isBooking = true
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            },
            onBookingCallback: function(flag) {
                this.isBooking = false
                document.body.setAttribute('style', this.style)
            },
            showMap: function() {
                this.show = true
            },
            getPosition: function(marker) {
                return {
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng)
                }
            },
            toggleInfo: function(marker, key) {
                this.infoPosition = this.getPosition(marker)
                this.infoTitle = marker.name
                this.infoDesc = marker.address
                if (this.infoCurrentKey == key) {
                    this.infoOpened = !this.infoOpened
                } else {
                    this.infoOpened = true
                    this.infoCurrentKey = key
                }
            },
            openMap: function(clinic) {
                // var left = (document.body.offsetWidth - 1000) / 2
                // left = left < 0 ? 0 : left
                // var options = `left=${left},top=200,toolbar=0,status=0,resizable=1,width=1000,height=536`
                // var url = `https://maps.google.com/?q=${(clinic.address || '').replace(/\d+室$/, '')}&ll=${clinic.lat},${clinic.lng}&t=f&z=15`
                // window.open(url, 'sharer', options)
                this.show = true
            }
        },
        watch: {
            '$route.params.clinicId': function(clinicId) {
                this.loading = true
                if(clinicId == parseInt(clinicId)) {
                    this.detail = JSON.parse(sessionStorage.getItem('detail'))
                    this.loading = false
                    this.getDoctors()
                } else {
                    this.$router.push('/hospital/list')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #clinic-detail {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        min-height: 500px;
        background: #fff;
        margin-top: 20px;
        position: relative;
    }

    .content > .clinic-info {
        margin: 20px 20px 0px 20px;
        border-radius: 8px;
        padding: 24px 20px 21px 20px;
        box-shadow: 1px 2px 8px 0px #E8EAF3;
        display: flex;
        flex-direction: row;
    }

    .content > .clinic-info > .icon-clinic {
        width: 70px;
        height: 70px;
        border-radius: 4px;
        background-color: #f7f7f7;
        margin-right: 23px;
        object-fit: contain;
        position: relative;
    }

    .content > .clinic-info > .icon-clinic[src='']::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 70px;
        background-color: #fff;
        background-image: url(../../../assets/imgs/pc/img_moren@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
    .content > .clinic-info > .col {
        display: flex;
        flex-direction: column;
    }

    .content > .clinic-info > .col > .name {
        color: #242020;
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
    }

    .content > .clinic-info > .col .icon-p {
        color: #242020;
        font-weight: 400;
        font-size: 16px;
        position: relative;
        padding-left: 23px;
    }

    .content > .clinic-info > .col .icon-p::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 14px;
        width: 14px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .content > .clinic-info > .col .tel {
        line-height: 12px;
        margin-top: 21px;
    }

    .content > .clinic-info > .col .tel::after {
        background-image: url(../../../assets/imgs/pc/img_iphone@2x.png);
    }

    .content > .clinic-info > .col .work-time {
        line-height: 16px;
        margin-top: 16px;
    }

    .content > .clinic-info > .col .work-time::after {
        background-image: url(../../../assets/imgs/pc/img_time@2x.png);
    }

    .content > .clinic-info > .col > .row {
        height: 17px;
        line-height: 17px;
        margin-top: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .content > .clinic-info > .col .address {
        line-height: 15px;
    }

    .content > .clinic-info > .col .address::after {
        width: 11px;
        background-image: url(../../../assets/imgs/pc/img_dib@2x.png);
    }

    .content > .clinic-info > .col .gps {
        margin-left: 18px;
        font-weight: 400;
        color: #36C4D0;
        font-size: 16px;
        line-height: 17px;
        cursor: pointer;
    }

    .content > .clinic-info > .col .intro {
        margin-top: 22px;
        color: #969696;
        line-height: 19px;
        font-size: 14px;
        font-weight: 400;
    }

    .breadcrumb {
	   display: flex;
	   flex-direction: row;
	   width: 1000px;
	   height: 42px;
	   box-sizing: border-box;
	   padding: 0px 16px;
	   background-color: #fff;
	   align-items: center;
	   font-size: 16px;
	   line-height: 15px;
	   font-weight: 400;
	   color: #969696;
	   user-select: none;
   }

	.breadcrumb dt {
		margin-right: 8px;
	}

	.breadcrumb dd {
	   color: #646464;
	   line-height: 17px;
	}

   .breadcrumb a {
	   margin: 0px 14px;
	   text-decoration: underline;
   }

   .breadcrumb span {
	   margin-left: 14px;
   }

    .content > .clinic-doctors {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        position: relative;
        box-sizing: border-box;
        padding-bottom: 60px;
        margin-top: 40px;
        min-height: 100px;
    }

    .content > .clinic-doctors > .hint {
        color: #242020;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 30px;
        margin-left: 20px;
    }

    .pop-window {
        display: flex;
        flex-direction: column;
    }

    .pop-window > .info-title {
        font-size: 24px;
        font-weight: bold;
        color: #008cfe;
        line-height: 24px;
    }

    .pop-window > .info-desc {
        margin-top: 13px;
        color: #636363;
        font-weight: 400;
        font-size: 16px;
    }

    .lds-ellipsis {
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #36C4D0;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }

    .doctor-list {
        display: grid;
        grid-template-columns: 228.75px 228.75px 228.75px 228.75px;
        grid-template-rows: 80px;
        grid-row-gap: 15px;
        grid-column-gap: 15px;
        margin-left: 20px;
        margin-bottom: 46px;
    }

    .doctor-list > .doctor-item {
        position: relative;
    }

    .doctor-list > .doctor-item:nth-child(3n+0) {
        margin-right: 0px;
    }

    .doctor-list > .doctor-item > .doctor-item-avatar {
        width: 70px;
        height: 70px;
        display: inline-block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_nvys@2x.png);
        position: absolute;
        left: 0;
        top: 0;
    }

    .doctor-list > .doctor-item > .doctor-item-avatar.man {
        background-image: url(../../../assets/imgs/pc/img_nanys@2x.png);
    }

    .doctor-list > .doctor-item > .doctor-item-name {
        position: absolute;
        top: 5px;
        left: 87px;
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
        color: #231F20;
    }

    .doctor-list > .doctor-item > .booking-count {
        display: none;
        position: absolute;
        left: 87px;
        top: 36px;
        color: #FF8000;
        font-size: 14px;
    }

    .doctor-list > .doctor-item > .booking-count.show {
        display: block;
    }

    .doctor-list > .doctor-item .doctor-item-address-list {
        position: absolute;
        bottom: 0px;
        left: 87px;
    }

    .doctor-list > .doctor-item .doctor-item-address-list .doctor-item-address {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        color: #636363;
        margin-left: 16px;
    }

    .doctor-list > .doctor-item .doctor-item-address-list::before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 9px;
        height: 11px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_cdibiao@2x.png);
    }

    .doctor-list > .doctor-item > .doctor-item-book {
        position: absolute;
        right: 0;
        top: 0;
        width: 64px;
        height: 28px;
        background-color: #FFE3D5;
        border-radius: 4px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        color: #FF8000;
        font-size: 16px;
        font-weight: bold;
    }

    .doctor-list > .doctor-item > .doctor-item-book:hover {
        opacity: 0.9;
    }
</style>
